<template>
  <div class="page-box blue-grey lighten-5">
    <div class="py-12">
      <h2 class="page-title primary--text text-center">{{ title }}!</h2>
      <div class="text-center" v-if="userID">
        <h3 class="text-muted mt-6">
          This page automatically open a new tab in your computer if you already
          logged-in.
        </h3>
        <v-btn
          color="primary"
          link
          @click="proceedToView"
          class="pl-5 pr-5 mt-8"
        >
          <v-icon class="mr-2" small>mdi-check</v-icon>
          Proceed to view in this browser
        </v-btn>
        <br />
        <v-btn link @click="goBack" class="pl-5 pr-5 mt-5"> Go Back </v-btn>
      </div>
      <div class="text-center" v-else>
        <h2 class="text-uppercase error-subtitle mt-6">
          You are requesting proteced resources.
        </h2>
        <h3 class="text-muted error-subtitle mt-6">
          Please log-in to your account first.
        </h3>
        <v-form ref="form" class="pl-5 pr-5 mt-6">
          <v-text-field
            v-model="loginData.email"
            label="Username"
            class="mt-4"
            required
            outlined
          ></v-text-field>
          <v-text-field
            v-model="loginData.password"
            :counter="10"
            :rules="passwordRules"
            label="Password"
            required
            outlined
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
          ></v-text-field>
          <v-btn
            :loading="loading"
            color="primary"
            link
            @click="login"
            class="pl-5 pr-5 w-100"
          >
            Login
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/authentication";

export default {
  data() {
    return {
      loginData: {
        email: "",
        password: "",
      },
      loading: false,
      showPassword: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length <= 10) || "Password must be less than 10 characters",
      ],
    };
  },
  computed: {
    viewUrl() {
      return decodeURI(this.$route.query.viewUrl || "");
    },
    userID() {
      return (
        this.$store.getters.userData && this.$store.getters.userData.userID
      );
    },
    title() {
      return this.$store.getters.userData && this.$store.getters.userData.userID
        ? "Thank you"
        : "Welcome";
    },
  },
  created() {
    this.emitOpenViewUrl();
  },
  methods: {
    proceedToView() {
      window.location = this.viewUrl || "https://google.com";
    },
    goBack() {
      window.history.go(-1);
    },
    emitOpenViewUrl() {
      if (this.userID && this.viewUrl) {
        this.sockets.common.invoke(
          "OpenScannedQRUrl",
          this.userID,
          this.viewUrl
        );
      }
    },
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var result = await AuthenticationService.login(
          this.loginData.email,
          this.loginData.password
        );
        this.loading = false;
        if (result.error) {
          this.$toast.error("Invalid Login credentials!");
          return;
        }
        this.$store.commit("SET_ACCESS_TOKEN", result.tokenID);
        this.$store.commit("SET_USER_DATA", result.authData);
        this.emitOpenViewUrl();
      }
    },
  },
};
</script>

<style lang="scss">
.page-box {
  height: 100%;
  width: 100%;
  position: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background: url("../assets/images/background/error-bg.jpg");
}
.page-title {
  font-size: 120px;
  line-height: 120px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
}
@media (max-width: 991px) {
  .page-title {
    font-size: 100px;
    line-height: 100px;
  }
}
@media (max-width: 767px) {
  .page-title {
    font-size: 80px;
    line-height: 80px;
  }
}
</style>